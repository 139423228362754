import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Test Week`}</em></p>
    <p>{`Weighted Strict Pullup to a 1RM`}</p>
    <p>{`Max Unbroken Kipping Pullups, 1 attempt`}</p>
    <p>{`Max Height Box Jump (no step in)`}</p>
    <p>{`200 Calorie Assault Bike for time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      